//import logo from '../../logo.svg';

export function Testimonials() {
  return (
    <div className="container">
      <main className="App-main">
        <h3 className="mb-4">Testimonials</h3>
      </main>
    </div>
  );
}
