import React from "react";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import HomeRepairServiceRoundedIcon from "@mui/icons-material/HomeRepairServiceRounded";
import "./style.scss";

const Pitch = () => {
  return (
    <div className="mt-4 mb-4 pitch-card">
      <div className="row">
        <div className="col-lg-12 mb-4 text-center">
          <div className="vc_custom_heading">
            3 steps to care for your property
          </div>
        </div>
        <div className="col-lg-4 mb-3 text-center">
          <LocalPhoneRoundedIcon aria-label="phone" />
          <div className="vc_custom_step">Request a free quote</div>
        </div>
        <div className="col-lg-4 mb-3 text-center">
          <CalendarMonthRoundedIcon aria-label="calendar" />
          <div className="vc_custom_step">Book your date</div>
        </div>
        <div className="col-lg-4 mb-3 text-center">
          <HomeRepairServiceRoundedIcon aria-label="tool box" />
          <div className="vc_custom_step">Let us do the work</div>
        </div>
      </div>
    </div>
  );
};

export default Pitch;
