import React from "react";
import "./style.scss";
import cardboard from "../../images/cardboard.png";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";

const Coupon = () => {
  return (
    <div className="coupon-card">
      <h3>We’d Love To Hear From You!</h3>
      <div>Contact Richard Vandop:</div>
      <div>
        <LocalPhoneRoundedIcon />
        <a href="tel:6047731238" tabIndex="-1">
          (604) 773-1238
        </a>
      </div>
      <div
        style={{
          backgroundImage: `url(${cardboard})`,
          opacity: ".15",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: "none",
          borderRadius: "0.25rem",
        }}
      />
    </div>
  );
};

export default Coupon;
