//import logo from '../../logo.svg';
import { Card } from "react-bootstrap";
import Coupon from "../../components/Coupon/Coupon";
import Pitch from "../../components/Pitch/Pitch";
import CouponBottom from "../../components/Coupon/CouponBottom";
import hedge from "../../images/hedge_bg3.png";
import hedgeTrimming from "../../images/services/hedge_trimming.jpg";
import lawnMower from "../../images/services/lawn_mower.jpg";
import shrubPruning from "../../images/services/shrub_pruning.jpg";
import weeding from "../../images/services/weeding.jpg";
import yardCleanup from "../../images/services/yard_cleanup.jpg";
import multch from "../../images/services/multch.jpg";
import washing from "../../images/services/washing.jpg";
import raking from "../../images/services/power-raking.jpeg";

import "./style.scss";

export function HomePage() {
  const items = [
    {
      title: "Lawn Maintenance",
      description:
        "Our lawn care service offers expert maintenance of a property's grass through efficient and reliable cutting, weed control, and seasonal fertilization.",
      image: lawnMower,
    },
    {
      title: "Yard Cleanup",
      description:
        "Yard cleanup involves thorough removal of winter debris and preparation of a property's lawn and garden for the growing season",
      image: yardCleanup,
    },
    {
      title: "Shrub Pruning",
      description:
        "Shrub pruning is a specialized service that involves the expert trimming and shaping of shrubs and bushes to maintain their health, appearance, and promote new growth",
      image: shrubPruning,
    },
    {
      title: "Hedge Trimming",
      description:
        "Hedge trimming is a service that involves the precise cutting and shaping of hedges to maintain their desired appearance and promote healthy growth",
      image: hedgeTrimming,
    },
    {
      title: "Weeding",
      description:
        "Weeding is a service that involves the removal of unwanted plants from a property's lawn and garden to maintain a pristine appearance and promote the growth of desired vegetation",
      image: weeding,
    },
    {
      title: "Flat Surface Power Washing",
      description:
        "Flat surface power washing is a service that utilizes high-pressure water to effectively remove dirt, grime, and other buildup from flat surfaces such as driveways, sidewalks, and patios",
      image: washing,
    },
    {
      title: "Fertilizing, Soil and Mulch",
      description:
        "Fertilizing, soil and mulch services involve the application of nutrients, soil improvement, and the placement of organic or inorganic materials to enhance the health and appearance of a property's lawn and garden",
      image: multch,
    },
    {
      title: "Power Raking",
      description:
        "Power raking improves air circulation and water absorption and helps to prevent the buildup of thatch, resulting in a much healthier lawn.",
      image: raking,
    },
  ];

  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(54,54,54,1) 0%, rgba(54,54,54,1) 25%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 100%)",
        }}
      >
        <div className="container ">
          <header>
            <div
              className="text-center bg-image"
              style={{
                backgroundImage: `url(${hedge})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center h-100"
                style={{ position: "relative" }}
              >
                <div className="text-white hero-wrapper">
                  <div className="hero-text-box">
                    <h1 className="hero-text">Garden and Lawn Care</h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            <div className="col-lg-7">
              <h2 className="mt-3 mt-lg-3" style={{ fontSize: "1.5rem" }}>
                Serving the Lower Mainland
              </h2>
              <div className="d-flex location-block">
                <div className="location-text" style={{ fontSize: "0.875rem" }}>
                  Westwind Property Maintenance is committed to offering
                  services to satisfy your landscaping and property upkeep
                  requirements. We're aware that every property is different and
                  needs a customised strategy. We provide individualised
                  services that are tailored to your needs and budget.
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <Coupon />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <main>
          <h3 className="mt-lg-4 mb-3" style={{ fontSize: "1.5rem" }}>
            Our Services
          </h3>
          <div className="service-cards">
            {items.map((item, index) => (
              <Card key={index} tabIndex={-1} aria-label={item.title}>
                <Card.Img
                  variant="top"
                  alt={item.title}
                  src={item.image ? item.image : "https://placehold.co/600x300"}
                  style={{ borderRadius: "0.75rem" }}
                />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text style={{ fontSize: "0.875rem" }}>
                    {item.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>

          <Pitch />

          <div className="faq">
            <div className="mb-2">
              <h3
                id="price-list"
                className="mb-2"
                style={{ fontSize: "1.5rem" }}
              >
                Price List 2024
              </h3>
              <div className="mb-3" style={{ fontSize: "0.875rem" }}>
                We are dedicated to delivering exceptional value to each and
                every client. My commitment extends beyond mere transactions,
                aiming to foster great relationships through exceptional service
                and a personable approach. Regardless of your budget
                constraints, my goal of quality and value remains my highest
                priority.
              </div>
            </div>
            <div className="price-list">
              <div className="price-list-item">
                <div
                  className="mb-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    color: "#2d2d2d",
                  }}
                >
                  Landscape Maintenance
                </div>
                <div className="sub-text mb-2">
                  All rates are hourly unless otherwise specified
                </div>
                <ul style={{ fontSize: "0.875rem" }}>
                  <li>
                    Mowing and edging{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    Weeding{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    Garden Bed Cleanup{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    Leaf Clean Up{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    Blowing{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    Planting and Watering{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    Pruning/Deadheading{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                  <li>
                    General garden care{" "}
                    <div>
                      <span>$45</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="price-list-item">
                <div
                  className="mb-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    color: "#2d2d2d",
                  }}
                >
                  Lawn Management
                </div>
                <div className="sub-text mb-2">
                  Price subject to change based on cost of seeds and fertilizer
                </div>
                <ul style={{ fontSize: "0.875rem" }}>
                  <li>
                    Power Raking/Aeration{" "}
                    <div>
                      <span>$100</span>
                    </div>
                  </li>
                  <li>
                    Seasonal Fertilization{" "}
                    <div>
                      <span>$40 per 1000 sq ft</span>
                    </div>
                  </li>
                  <li>
                    Moss Control Fertilization{" "}
                    <div>
                      <span>$40 per 1000 sq ft</span>
                    </div>
                  </li>
                  <li>
                    Overseeding{" "}
                    <div>
                      <span>$45 per 1000 sq ft</span>
                    </div>
                  </li>
                  <li>
                    Grub Control (Prevention and treatment)
                    <div>
                      <span>$75 per 1500 sq ft</span>
                    </div>
                  </li>
                  <li>
                    Hedge Trimming{" "}
                    <div>
                      <span>$70</span>
                    </div>
                  </li>
                  <li>
                    Power Washing{" "}
                    <div>
                      <span>$75</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <CouponBottom />
        </main>
      </div>
    </>
  );
}
